.modal {
  padding-top: 10px;
}

.input {
  width: 100%;
}

.chip {
  max-height: 23px;
  padding: 2px;
  margin-right: 3px !important;

  svg {
    height: 14px;
  }

  span {
    padding: 0 10px;
  }
}

.dialog {
  button {
    padding: 6px 16px;
    border-radius: var(--mui-shape-borderRadius);
    color: var(--mui-palette-primary-contrastText);
  }
}

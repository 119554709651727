.header-container {
  background: var(--mui-palette-primary-contrast);
}
.button-container {
  display: flex;
  padding: 12px 16px;
  cursor: pointer;
  align-items: center;
}
.button-container:hover {
  background: var(--mui-palette-grey-200);
}
.icon-container {
  margin: auto 8px auto 0;
}

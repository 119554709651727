.modalContainer {
  overflow: hidden;
  max-height: 600px;
  overflow-y: auto;
}
.modalContainer :global(.MuiFormControl-root) {
  margin: 11px 0;
}
.textarea {
  margin-bottom: 10px;
  width: 100%;
  padding: 0;
}

.textarea :global(.MuiOutlinedInput-root) {
  padding: 4px 0 5px;
}

.input {
  margin-bottom: 10px;
  width: 100%;
}

.MuiGridItem {
  display: flex;
  justify-content: flex-end;
}

.userStatusIcon {
  width: 24px;
  height: 24px;
}

.actionMenuItem.weightGainSelector {
  padding: 6px 16px;
  width: 100%;
  justify-content: flex-start;
}

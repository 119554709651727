@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 200;
  src: url("./light/latest.woff2") format("woff2"), url("./light/latest.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 200;
  src: url("./semilight/latest.woff2") format("woff2"), url("./semilight/latest.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  src: url("./normal/latest.woff2") format("woff2"), url("./normal/latest.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  src: url("./semibold/latest.woff2") format("woff2"), url("./semibold/latest.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  src: url("./bold/latest.woff2") format("woff2"), url("./bold/latest.woff") format("woff");
}

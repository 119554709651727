.root {
  align-items: center;
  border-radius: 4px;
  display: flex;
  width: 72px;
  height: 72px;
  justify-content: center;
  background: var(--mui-palette-primary-main);
  /* stylelint-disable custom-property-pattern */
  color: var(--mui-palette-primary-contrastText);
}

.root svg {
  font-size: 40px;
}

.colorError,
.colorSuccess,
.colorWarning {
  color: var(--mui-palette-common-white);
}

.colorError {
  background: var(--mui-palette-error-main);
}

.colorSuccess {
  background: var(--mui-palette-success-main);
}

.colorWarning {
  background: var(--mui-palette-warning-main);
}

.wrapper {
  display: flex;
  color: var(--mui-palette-text-primary);
  padding-bottom: 8px;
  border-bottom: 1px solid var(--mui-palette-grey-500);
}
.contentPart {
  flex: 0 0 50%;
  word-break: break-word;
  word-wrap: break-word;
  padding-right: 32px;
}
.textWithEllipsis {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.titlePart {
  font-weight: 600;
  padding-right: 8px;
}
.disabled {
  color: var(--mui-palette-grey-300);
}
.error {
  color: var(--mui-palette-error-main);
}

.content {
  background-color: var(--mui-palette-common-white);
  border-radius: 4px;
  box-shadow: 0 1px 8px rgba(41, 49, 53, 0.05);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.langSwitcher {
  background: #fff;
  height: 24px;
}

.documentLink {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.form-field-inn {
  :global(.MuiFormHelperText-root) {
    height: 0;
  }
}

.icon {
  color: var(--mui-palette-common-white);
  height: 1em;
  width: 1em;
}

.container {
  display: flex;
  flex-direction: column;
  margin: -16px;
}

.commercialItems {
  padding: 24px;
  background-color: var(--mui-palette-common-white);
}

.experimentalItems {
  padding: 24px;
}

.wrapper {
  display: 'flex';
  width: '100%';
  color: var(--mui-palette-text-primary);
}
.titlePart {
  display: 'flex';
  align-items: 'center';
  flex: '0 0 70%';
  word-break: 'break-word';
  word-wrap: 'break-word';
}
.rightSide {
  display: 'flex';
  justify-content: 'flex-end';
  flex: '0 0 30%';
  padding-left: '24px';
  word-break: 'break-word';
  word-wrap: 'break-word';
}
.halfField {
  flex: '0 0 50%';
  padding-left: 0;
}

.wrapper {
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  color: #f50057;
  font-weight: 600;
}
.docTypeWrap {
  display: flex;
  align-items: center;
}
link {
  &:hover {
    color: #f50057;
  }
}
.wrapperTitle {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.text {
  color: #ffffff;
  padding: 0 16px 0 8px;
  font-size: 12;
}
.linkMail {
  padding-left: 8;
  cursor: pointer;
}
.iconDownload {
  color: #f50057;
  cursor: pointer;
}
.iconMessage {
  color: #f50057;
  padding-top: 6;
}

.wrapperDatatable {
  margin-top: 0;
}
.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

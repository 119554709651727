/* stylelint-disable */
.App {
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

:global(.MuiPopover-root .MuiList-root) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

:global(.MuiPopover-root .MuiList-root li[role='option']) {
  width: 100%;
}

/**
  Remove Arrows/Spinners for number input

  Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none;
}

:global(.whiteColor) {
  color: var(--mui-palette-common-white) !important;
}

:global(.steps) button[aria-selected='true'] {
  color: var(--mui-palette-common-white) !important;
}

.wrapper {
  position: relative;
}

.description {
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 99;
  color: var(--mui-palette-secondary-main);
  cursor: help;
}

.root {
  border-radius: 4px;
  position: relative;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: border-box;
}
.root * {
  box-sizing: border-box;
}

.disabled {
  opacity: 1;
}

.head {
  display: flex;
  align-items: center;
  gap: 16px;
}

.status {
  flex: 1 0 auto;
  margin-left: 16px;
}

.icon {
  svg {
    width: 28px;
    height: 28px;
  }
  height: 48px;
  width: 48px;
  flex: 0 0 auto;
}

.startDate {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--mui-palette-text-disabled);
  margin-bottom: 4px;
}

.label {
  font-size: 14px;
  line-height: 116.7%;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.container {
  display: flex;
  font-weight: 600;
  align-items: 'center';
  overflow: hidden;
}
.iconContainer {
  display: flex;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}
.valueContainer {
  display: flex;
  margin: 0;
  align-items: center;
}

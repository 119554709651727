.root {
  outline: none;
}
.head {
  display: flex;
  align-items: center;
  padding: 12px 16px;
}
.icon {
  margin-right: 8;
}
.notification {
  background: var(--mui-palette-common-black);
  color: var(--mui-palette-primary-contrastText);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3;
  padding: 8px 16px;
}
.title {
  font-size: 11px;
  line-height: 1.45;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
.children {
  padding: 16;
  box-shadow: 0 1px 0 var(--mui-palette-action-selected);
}

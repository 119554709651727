.container {
  padding-top: 10px;
}
.dialog {
  button {
    padding: 6px 16px;
    border-radius: var(--mui-shape-borderRadius);
    color: var(--mui-palette-primary-contrastText);
  }
}

.receiptFooterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}

.docTypeWrap {
  display: flex;
  align-items: center;
}

.iconWrap {
  padding-right: 16px;
}
.wrapper {
  cursor: pointer;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-start;
  color: var(--mui-palette-secondary-main);
  font-weight: 600;
}

.link :hover {
  color: var(--mui-palette-secondary-main);
}

.wrapperTitle {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.text {
  color: var(--mui-palette-common-white);
  padding: 0 16px 0 8px;
  font-size: 12px;
}

.linkMail {
  padding-left: 8px;
  cursor: pointer;
}

.iconDownload {
  color: var(--mui-palette-secondary-main);
  cursor: pointer;
}

.iconMessage {
  color: var(--mui-palette-secondary-main);
  padding-top: 6px;
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
}

.actionButton {
  color: var(--mui-palette-secondary-main);
}

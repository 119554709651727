.root {
  align-items: center;
  box-shadow: 0 1px 0 var(--mui-palette-action-selected);
  display: flex;
  padding: 12px 16px;
}
.icon {
  width: 32px;
  height: 32px;
  background: var(--mui-palette-action-selected);
  border-radius: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.label {
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: var(--mui-palette-action-active);
}
.value {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.02em;
}

.root {
  background-color: var(--mui-palette-grey-50);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}

.block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  max-width: 768px;
  padding: 0 32px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.head {
  background-color: var(--mui-palette-primary-main);
  margin: 0 0 -32px;
  padding: 88px 0 48px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.title {
  color: var(--mui-palette-common-white);
  box-sizing: border-box;
}

.content {
  border-radius: 4px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.05);
  padding: 32px;
  min-height: 192px;
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background: var(--mui-palette-common-white);
  box-sizing: border-box;

  @media (max-width: 1000px) {
    box-shadow: none;
  }
}

.footer {
  box-shadow: inset 0 1px 0 var(--mui-palette-grey-50);
  font-size: 14px;
  line-height: 1;
  margin-top: 40px;
  padding: 20px 70px;
  box-sizing: border-box;
}

.tooltipList {
  margin: 0;
  padding: 8px 0 0 16px;
}

.contentWrapper {
  height: calc(100% - 64px);
}

.settingsGridTypograhy {
  margin-bottom: 8;
}

.groupItemWrapper {
  margin-bottom: 20px;
}
.infoTitle {
  margin-bottom: 16px;
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.pageWrapper {
  background-color: var(--mui-palette-background-default);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
}
.ACTIVE {
  color: var(--mui-palette-Alert-successIconColor);
}
.BLOCKED {
  color: var(--mui-palette-Alert-errorIconColor);
}
.DRAFT {
  color: var(--mui-palette-Alert-errorIconColor);
}

.modalContainer {
  overflow: hidden;
  max-height: 600px;
  overflow-y: auto;
}

.modalContainer :global(.MuiFormControl-root) {
  margin: 10px 0;
}

.input {
  width: 100%;
}

.textArea {
  width: 100%;
}

.textArea :global(.MuiInputBase-root) {
  padding: 0;
}

.input svg {
  display: none;
}

.MuiGridItem {
  display: flex;
  justify-content: flex-end;
}

.modalIcon {
  margin-bottom: 0;
  padding: 0;
  width: 24px;
}

.modalIcon div svg {
  width: 24px;
  height: 24px;
}

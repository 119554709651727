html {
  width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #f1f0f0;
  color: #63666a;
  font-family: 'Calibri', sans-serif;
  margin: 0;
  min-height: 100%;
  overflow-y: auto !important;
  padding: 0;
}

body.ReactModal__Body--open-reportHelp {
  overflow: hidden;
}

svg {
  shape-rendering: geometricPrecision;
  vertical-align: top;
}

::selection {
  background: #2b5d80;
  color: white;
}

/* хак, чтобы спрятать странный блок */
/* добавляющийся к body при клике на любой пункт меню */
div[style^='font-size: 14px; font-weight: 200; position: absolute; visibility: hidden; pointer-events: none; left: -10000px;'] {
  display: none;
}

.mt15 {
  margin-top: 15px;
}

.breadcrumbsWrap nav {
  margin-bottom: 0;
}

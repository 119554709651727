.container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  display: inline;
}

.tooltipIsWhiteColor {
  background-color: var(--mui-palette-common-white);
  color: var(--mui-palette-text-primary);
}

.tooltip {
  color: var(--mui-palette-common-white);
}
